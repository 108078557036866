import * as React from "react";
import CenteredHeading from "../components/CenteredHeading";
import Features from "../components/Features";
import Seo from "../components/Seo";

const ServicesPage = () => {
  return (
    <>
      <Seo title="Services" />
      <CenteredHeading
        pageTitle="Services"
        mainText="Outperform the competition"
      />
      <Features />
    </>
  );
};

export default ServicesPage;
