import * as React from "react";
import {
  GlobeIcon,
  LightningBoltIcon,
  CursorClickIcon,
  RefreshIcon,
  LockClosedIcon,
  BeakerIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";

const transferFeatures = [
  {
    id: 1,
    name: "Fast by default",
    description:
      "By using a lightweight JavaScript framework like Gatsby or Next.js for the frontend, your site is automatically bundled into the smallest size possible, resulting in lightning fast load times.",
    icon: LightningBoltIcon,
  },
  {
    id: 2,
    name: "More security",
    description:
      "A headless solution provides better security by limiting the number of attack points for malicious actors.",
    icon: LockClosedIcon,
  },
  {
    id: 3,
    name: "Easily update content",
    description:
      "Businesses need to keep running without calling a developer every time they need to make a change. A headless CMS enables easy content updates anytime.",
    icon: RefreshIcon,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: "Accessibility",
    description:
      "Accessibility lawsuits are increasing every year. But more importantly, users with disabilities need to be able to access your site the way any other user would.",
    icon: CursorClickIcon,
  },
  {
    id: 2,
    name: "A/B Testing",
    description:
      "Want to try something new? Our A/B tests deliver more conversions, backed by a proven and scientific method.",
    icon: BeakerIcon,
  },
  {
    id: 3,
    name: "Internationalization",
    description:
      "Reach a larger audience by offering your app, store, or blog in different languages.",
    icon: GlobeIcon,
  },
];

const Features = () => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Headless all the things
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              We believe the future of the web is headless. More and more
              businesses are modernizing their sites with this approach. No
              matter where your data lives - WordPress, Drupal, Shopify, etc -
              enjoy the benefits of headless with increased speed, security, and
              a great editing experience.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <div>
              <StaticImage
                className="mx-12 lg:ml-24 lg:mr-auto rounded-md lg:max-w-md"
                src="../../images/racecar2.png"
              />
            </div>
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                But wait - there's more!
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Check out how else we can help you grow and improve your digital
                businesses.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <StaticImage
                className="relative mx-12 lg:ml-24 lg:mr-auto rounded-md lg:max-w-md"
                src="../../images/features-img.jpg"
                width={800}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
